<template>
  <div :class="['collapsible-card', borderVariant, cardClasses, {'expanded': expanded, 'collapsed': !expanded, 'card': !plain, 'border-0': hideCollapsedHeader && !expanded}]">
    <div v-if="showHeader"
         data-toggle="collapse"
         class="cursor-pointer"
         :class="[
            'collapsible-header',
            headerPositionClass,
            headerFlexAlignment,
            (expanded || !hideCollapsedHeader) ? headerClasses : '',
            {
              'card-header': !plain,
              'compact': compactHeader,
              'passive-error': errorHighlightPassive && !errorHighlight,
              'error': errorHighlight,
              'warning-passive': warningHighlightPassive && !warningHighlight,
              'warning': warningHighlight,
              'collapsed': !expanded,
              'no-icon': !showIcon,
              'd-none': hideCollapsedHeader && !expanded,
              'd-flex': expanded || (!hideCollapsedHeader && !expanded)
            }
          ]"
         :style="stickyOffsetStyle"
         @click.self="click">
      <slot name="pre-header"></slot>
      <p :class="[heading, 'ml-3 mb-0 flex-grow-1', 'card-title', {'m-0': !plain, 'p-0': !plain}]"
         @click="click">
        <slot v-bind:expanded="expanded" name="header"></slot>
      </p>
      <span v-if="errorHighlight || warningHighlight || warningHighlightPassive"
            :class="['error-bg-icon', 'round', 'position-absolute', 'left-2', {
                'danger': errorHighlight,
                'warning': (warningHighlight || warningHighlightPassive) && !errorHighlight,
                'lg': !compactHeader
              }]"><fluency-icon type="warning" class="ml-1"></fluency-icon></span>
      <slot name="post-header"></slot>
    </div>

    <b-collapse v-model="expanded"
                :visible="visible"
                :accordion="accordion"
                @show="show"
                @shown="$emit('shown')"
                @hide="hide"
                @hidden="$emit('hidden')">
      <div :class="[{'card-body': !plain}, bodyClasses]">
        <slot></slot>
      </div>
      <div v-if="$slots.hasOwnProperty('footer')" :class="[{'card-footer': !plain, 'compact': compactFooter}]">
        <slot name="footer"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  name: 'collapsibleCard',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: 'h4'
    },
    plain: {
      type: Boolean,
      default: false
    },
    compactHeader: {
      type: Boolean,
      default: false
    },
    compactFooter: {
      type: Boolean,
      default: false
    },
    errorHighlight: {
      type: Boolean,
      default: false
    },
    errorHighlightPassive: {
      type: Boolean,
      default: false
    },
    warningHighlight: {
      type: Boolean,
      default: false
    },
    warningHighlightPassive: {
      type: Boolean,
      default: false
    },
    cardClasses: {
      type: String,
      default: ''
    },
    headerClasses: {
      type: String,
      default: ''
    },
    bodyClasses: {
      type: String,
      default: ''
    },
    headerPosition: {
      type: String,
      default: 'relative'
    },
    stickyHeaderOffset: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    accordion: {
      type: String, // specify an accordion group string
      default: null
    },
    hideCollapsedHeader: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    borderVariant: {
      type: String,
      default: null
    },
    headerFlexAlignment: {
      type: String,
      default: 'align-items-center'
    },
    customId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      expanded: this.visible,
      headerPositionClass: (this.headerPosition === 'sticky') ? 'sticky-top' : 'position-relative',
      stickyOffsetStyle: (this.stickyHeaderOffset) ? `top: ${this.stickyHeaderOffset}` : ''
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        if (newVal) {
          this.show()
        } else {
          this.hide()
        }
      },
      immediate: true
    }
  },
  methods: {
    show: function () {
      this.expanded = true
      this.$emit('update:visible', true)
      this.$emit('show')
    },
    hide: function () {
      this.expanded = false
      this.$emit('update:visible', false)
      this.$emit('hide')
    },
    click: function (e) {
      e.preventDefault()
      this.$emit('click', e)
      this.expanded ? this.hide() : this.show()
    }
  }
}
</script>

<style lang="scss" scoped>
  $card-cap-bg: rgb(255, 240, 240) !default;

  .collapsible-card {
    a {
      color: $fluency-gray;
    }
  }

  .collapsible-card.danger .collapsible-header {
    border-left-color: $danger !important;
  }
  .collapsible-card.warning .collapsible-header {
    border-left-color: $warning !important;
  }
  .collapsible-card.success .collapsible-header {
    border-left-color: $success !important;
  }
  .collapsible-card.secondary .collapsible-header {
    border-left-color: $secondary !important;
  }

  .collapsible-header {
    p {
      font-weight: 375;
    }

    &.compact {
      padding: 0.25em 0.25em;
    }

    &.passive-warning {
      background-color: $card-cap-bg;
      border-left-color: $warning !important;
    }

    &.warning {
      background: lightyellow;
      border-left-color: $warning !important;
    }

    &.passive-error {
      border-left-color: $danger !important;
    }

    &.error {
      background: #fadbdf;
      border-left-color: $danger !important;
    }

    &:before {
      left: 5px;
    }
  }

  .card-footer {
    &.compact {
      padding: 0.25em 0.25em;
    }
  }

  .error-bg-icon {
    z-index: 1;
    top: 7px;
    left: 12px;
    opacity: 0.1;
  }

  .card-title {
    z-index: 2;
  }

  .expanded > .sticky-top {
    z-index: 1021;
  }

  .no-icon:before {
    display: none !important;
  }
</style>
